import React, { useState, useEffect, useMemo } from 'react';
import { TokenCard } from './TokenCard';
import { MobileTokenCard } from './MobileTokenCard';
import Filters from './Filters';
import { getTrendingData } from '../utils/dataFetcher';

const TokenCategory = ({ 
  title, 
  tokens = [], 
  icon, 
  darkMode, 
  filterDropdownOpen, 
  setFilterDropdownOpen,
  layoutSettings,
  isKOTH = false
}) => {
  const [sortOrder, setSortOrder] = useState('1m');
  const [boostRange, setBoostRange] = useState({ min: '', max: '' });
  const [showDexPaidOnly, setShowDexPaidOnly] = useState(false);
  const [trendingData, setTrendingData] = useState([]);
  const [dexPaidStatusCache, setDexPaidStatusCache] = useState({});
  const [filteredTokens, setFilteredTokens] = useState([]);

  useEffect(() => {
    if (title.toLowerCase() === 'trending') {
      const data = getTrendingData(sortOrder);
      setTrendingData(data);
    }
  }, [title, sortOrder]);

  const handleSortChange = (newOrder) => {
    setSortOrder(newOrder);
    if (title.toLowerCase() === 'trending') {
      const data = getTrendingData(newOrder);
      setTrendingData(data);
    }
  };

  const handleBoostRangeChange = (min, max) => {
    setBoostRange({ min, max });
  };

  const handleDexPaidFilter = (showOnly) => {
    setShowDexPaidOnly(showOnly);
  };

  const checkDexPaidStatus = async (chainId, tokenAddress) => {
    try {
      if (dexPaidStatusCache[tokenAddress] !== undefined) {
        return dexPaidStatusCache[tokenAddress];
      }
      
      const response = await fetch(`https://api.dexscreener.com/orders/v1/${chainId}/${tokenAddress}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      const data = await response.json();
      const isDexPaid = data.some(order => order.status === 'approved');
      setDexPaidStatusCache(prev => ({ ...prev, [tokenAddress]: isDexPaid }));
      return isDexPaid;
    } catch (error) {
      console.error('Error checking DEX paid status:', error);
      return false;
    }
  };

  const filterAndSortTokens = async () => {
    let sorted = [...tokens];
    
    if (title.toLowerCase() === 'kings of the hill' || title.toLowerCase() === 'king of the hill') {
      if (showDexPaidOnly) {
        const filteredTokens = [];
        for (let token of sorted) {
          const isDexPaid = await checkDexPaidStatus(token.chainId, token.address);
          if (isDexPaid) filteredTokens.push(token);
        }
        sorted = filteredTokens;
      }
    } else if (title.toLowerCase() === 'dex paid') {
      switch(sortOrder) {
        case 'marketcap':
          sorted.sort((a, b) => (b.usd_market_cap || 0) - (a.usd_market_cap || 0));
          break;
        case 'marketcap_asc':
          sorted.sort((a, b) => (a.usd_market_cap || 0) - (b.usd_market_cap || 0));
          break;
        case 'recent':
          sorted.sort((a, b) => {
            const getTimestamp = (token) => {
              if (token.dex_paid_timestamp) return token.dex_paid_timestamp;
              if (token.open_timestamp) return token.open_timestamp;
              if (token.dex_listing_timestamp) return token.dex_listing_timestamp;
              
              return token.created_timestamp || token.pool_creation_timestamp || 0;
            };

            return getTimestamp(b) - getTimestamp(a);
          });
          break;
        default:
          break;
      }
    } else if (title.toLowerCase() === 'boosted') {
      switch(sortOrder) {
        case 'desc':
          sorted.sort((a, b) => (b.boost_count || 0) - (a.boost_count || 0));
          break;
        case 'asc':
          sorted.sort((a, b) => (a.boost_count || 0) - (b.boost_count || 0));
          break;
        case 'recent':
          sorted.sort((a, b) => {
            const getTimestamp = (token) => {
              if (token.last_boost_timestamp) return token.last_boost_timestamp;
              if (token.boost_timestamp) return token.boost_timestamp;
              if (token.first_boost_timestamp) return token.first_boost_timestamp;
              
              return token.created_timestamp || token.pool_creation_timestamp || 0;
            };

            return getTimestamp(b) - getTimestamp(a);
          });
          break;
        default:
          break;
      }

      if (boostRange.min !== '' || boostRange.max !== '') {
        sorted = sorted.filter(token => {
          const tokenBoost = parseInt(token.boost_count || 0);
          const passesMinCheck = boostRange.min === '' || tokenBoost >= parseFloat(boostRange.min);
          const passesMaxCheck = boostRange.max === '' || tokenBoost <= parseFloat(boostRange.max);
          return passesMinCheck && passesMaxCheck;
        });
      }
    }
    
    return sorted;
  };

  useEffect(() => {
    const updateFilteredTokens = async () => {
      const sorted = await filterAndSortTokens();
      setFilteredTokens(sorted);
    };
    updateFilteredTokens();
  }, [tokens, sortOrder, title, boostRange, trendingData, showDexPaidOnly]);

  return (
    <div className={`bg-white/50 backdrop-blur-sm px-2 max-h-screen mb-2 overflow rounded-xl dark:bg-[#363945] shadow-sm border border-gray-200/50 ${
      layoutSettings?.columns === '3-col' ? 'col-span-1' : ''
    }`}>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center pt-2 gap-2">
          <span className="text-xl">{icon}</span>
          <span className="dark:text-white font-bold text-md text-gray-900">{title}</span>
        </div>
        <Filters 
          type={title}
          onSortChange={handleSortChange}
          onBoostRangeChange={handleBoostRangeChange}
          onDexPaidFilter={handleDexPaidFilter}
        />
      </div>
      <div className="overflow-y-auto rounded-xl h-full  space-y-4">
        {filteredTokens.length > 0 ? (
          filteredTokens.map((token, index) => (
            <TokenCard 
              key={token.mint || token.address} 
              token={token} 
              darkMode={darkMode}
              isKOTH={isKOTH}
              kothRank={index + 1}
              timeframe={title.toLowerCase() === 'trending' ? sortOrder : undefined}
            />
          ))
        ) : (
          <div className="text-center py-4 text-gray-500 dark:text-gray-400">
            No tokens found
          </div>
        )}
      </div>
    </div>
  );
};

export default TokenCategory;
