import React, { useState, useRef, useEffect } from 'react';

const Filters = ({ type, onSortChange, onBoostRangeChange, onDexPaidFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [minBoost, setMinBoost] = useState('');
  const [maxBoost, setMaxBoost] = useState('');
  const [sortOrder, setSortOrder] = useState('1m');
  const [showDexPaidOnly, setShowDexPaidOnly] = useState(false);
  const dropdownRef = useRef(null);

  // Filter options based on section type
  const getFilterOptions = () => {
    const normalizedType = type.toLowerCase().trim();
    
    switch(normalizedType) {
      case 'boosted':
        return {
          title: 'Sort by Boosts',
          options: [
            { label: 'Greatest to Least', value: 'desc' },
            { label: 'Least to Greatest', value: 'asc' },
            { label: 'Most Recent', value: 'recent' }
          ]
        };
      case 'dex paid':
        return {
          title: 'Sort Options',
          options: [
            { label: 'Highest Market Cap', value: 'marketcap' },
            { label: 'Lowest Market Cap', value: 'marketcap_asc' },
            { label: 'Most Recent', value: 'recent' }
          ]
        };
      case 'trending':
        return {
          title: 'Time Frame',
          options: [
            { label: '1 Minute', value: '1m' },
            { label: '5 Minutes', value: '5m' },
            { label: '6 Hours', value: '6h' },
            { label: '24 Hours', value: '24h' }
          ]
        };
      case 'kings of the hill':
      case 'king of the hill':
        return {
          title: 'Filter Options',
          options: [
            { label: 'Show All', value: 'all' },
            { label: 'DEX Paid Only', value: 'dexPaid' }
          ]
        };
      default:
        return null;
    }
  };

  // Check DEX paid status (used by KOTH filter)
  const checkDexPaidStatus = async (chainId, tokenAddress) => {
    try {
      const response = await fetch(`https://api.dexscreener.com/orders/v1/${chainId}/${tokenAddress}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        }
      });
      const data = await response.json();
      return data.some(order => order.status === 'approved');
    } catch (error) {
      console.error('Error checking DEX paid status:', error);
      return false;
    }
  };

  const handleOptionClick = (option) => {
    const normalizedType = type.toLowerCase().trim();
    
    if (normalizedType === 'trending' || normalizedType === 'boosted' || normalizedType === 'dex paid') {
      setSortOrder(option.value);
      onSortChange?.(option.value);
    } else if (normalizedType === 'kings of the hill' || normalizedType === 'king of the hill') {
      if (option.value === 'dexPaid') {
        const newValue = !showDexPaidOnly;
        setShowDexPaidOnly(newValue);
        onDexPaidFilter?.(newValue);
      } else if (option.value === 'all') {
        setShowDexPaidOnly(false);
        onDexPaidFilter?.(false);
      }
    }
    setIsOpen(false); // Close dropdown after selection
  };

  // Handle boost range changes
  const handleBoostRangeChange = (min, max) => {
    const minVal = min === '' ? '' : parseInt(min);
    const maxVal = max === '' ? '' : parseInt(max);
    setMinBoost(min);
    setMaxBoost(max);
    onBoostRangeChange?.(minVal, maxVal);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="dark:text-gray-400 text-gray-500 p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
      >
        •••
      </button>
      
      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 origin-top-right bg-white dark:bg-[#1c1d25] rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="p-4 space-y-4">
            {/* Dynamic Filter Options */}
            <div className="space-y-2">
              <h3 className="text-sm font-medium dark:text-white">
                {getFilterOptions()?.title}
              </h3>
              <div className="space-y-1">
                {getFilterOptions()?.options.map((option) => (
                  <button
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    className={`w-full text-left px-2 py-1 rounded ${
                      type.toLowerCase().includes('king') 
                        ? (option.value === 'dexPaid' && showDexPaidOnly) || (option.value === 'all' && !showDexPaidOnly)
                          ? 'bg-[#00ff9d]/20 text-[#00ff9d]'
                          : 'dark:text-gray-400 hover:bg-[#00ff9d]/10'
                        : sortOrder === option.value
                          ? 'bg-[#00ff9d]/20 text-[#00ff9d]'
                          : 'dark:text-gray-400 hover:bg-[#00ff9d]/10'
                    }`}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>

            {/* Boost Range Filter - Only show for Boosted section */}
            {type.toLowerCase() === 'boosted' && (
              <div className="space-y-2">
                <h3 className="text-sm font-medium dark:text-white">Boost Range</h3>
                <div className="flex gap-2">
                  <input
                    type="number"
                    min="0"
                    placeholder="Min"
                    value={minBoost}
                    onChange={(e) => handleBoostRangeChange(e.target.value, maxBoost)}
                    className="w-1/2 px-2 py-1 rounded bg-transparent border dark:border-gray-700 dark:text-gray-400"
                  />
                  <input
                    type="number"
                    min="0"
                    placeholder="Max"
                    value={maxBoost}
                    onChange={(e) => handleBoostRangeChange(minBoost, e.target.value)}
                    className="w-1/2 px-2 py-1 rounded bg-transparent border dark:border-gray-700 dark:text-gray-400"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
