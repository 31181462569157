import axios from 'axios';
import { cacheManager } from './cacheManager';
import { sellixManager } from './sellixManager';
import dexPaidData from './Scraper/dex_paid.json';
import newPairRanks from './Scraper/new_pair_ranks.json';
import pumpRanks from './Scraper/pump_ranks.json';
import pumpVision from './Scraper/pumpvision.json';
import coinData from './Scraper/data.json';
import kothData from './Scraper/koth.json';
import { proxyManager } from './proxyManager';
import swaps1m from './Scraper/swaps_1m.json';
import swaps5m from './Scraper/swaps_5m.json';
import swaps1h from './Scraper/swaps_1h.json';
import swaps6h from './Scraper/swaps_6h.json';
import swaps24h from './Scraper/swaps_24h.json';

const API_URLS = {
  boostedTokensLatest: 'https://api.dexscreener.com/token-boosts/latest/v1',
  dexscreenerTokens: 'https://api.dexscreener.com/latest/dex/tokens/'
};

// Rate limits for DexScreener
const RATE_LIMITS = {
  dexScreenerOther: {
    requestsPerMinute: 60, // Updated to 300 requests per minute
    intervalMs: 1000 // 200ms between requests
  }
};

// Rate limiter for DexScreener
const rateLimiters = {
  boosts: createRateLimiter(RATE_LIMITS.dexScreenerOther.intervalMs)
};

function createRateLimiter(intervalMs) {
  let lastCallTime = 0;
  
  return async function rateLimitedCall(fn) {
    const now = Date.now();
    const timeToWait = Math.max(0, lastCallTime + intervalMs - now);
    
    if (timeToWait > 0) {
      await new Promise(resolve => setTimeout(resolve, timeToWait));
    }
    
    lastCallTime = Date.now();
    return fn();
  };
}

async function checkAccessAndExecute(fn) {
  const devPassword = localStorage.getItem('stalkfun_dev_access');
  const storedAccess = localStorage.getItem('stalkfun_access');
  
  const hasAccess = 
    (devPassword && sellixManager.checkDevAccess(devPassword)) ||
    (storedAccess && new Date(JSON.parse(storedAccess).expiresAt) > new Date());

  if (!hasAccess) {
    return { accessRequired: true };
  }

  return fn();
}

// Keep track of boosted tokens globally
let boostedTokens = [];

// Update fetchBoostedTokens to store the results and use batch requests
async function fetchBoostedTokens() {
  try {
    const response = await axios.get(API_URLS.boostedTokensLatest);
    
    // Filter for Solana tokens ending in 'pump'
    const solanaTokens = response.data
      .filter(token => token.chainId === 'solana' && token.tokenAddress.toLowerCase().endsWith('pump'));

    // Get all token addresses
    const addresses = solanaTokens.map(token => token.tokenAddress).join(',');
    
    try {
      // Fetch DexScreener data
      const dexResponse = await rateLimiters.boosts(() => 
        axios.get(`${API_URLS.dexscreenerTokens}${addresses}`)
      );

      // Create lookup maps for token data
      const dexData = {};
      if (dexResponse.data.pairs) {
        dexResponse.data.pairs.forEach(pair => {
          const tokenAddress = pair.baseToken?.address?.toLowerCase();
          if (tokenAddress) {
            dexData[tokenAddress] = {
              name: pair.baseToken.name,
              symbol: pair.baseToken.symbol,
              marketCap: pair.fdv || pair.marketCap || 0,
              volume24h: pair.volume?.h24 || 0,
              txns24h: pair.txns?.h24 || 0,
              priceUsd: pair.priceUsd || 0,
              liquidity: pair.liquidity?.usd || 0,
              pairCreatedAt: pair.pairCreatedAt || 0
            };
          }
        });
      }

      // Map tokens with complete data
      boostedTokens = solanaTokens.map(token => {
        const tokenAddress = token.tokenAddress.toLowerCase();
        const tokenData = dexData[tokenAddress] || {};
        
        // Get token data from all available sources
        const pumpRankData = pumpRanks.data?.pumps?.find(t => t.address?.toLowerCase() === tokenAddress);
        const swaps24hData = swaps24h.data?.rank?.find(t => t.address?.toLowerCase() === tokenAddress);
        
        return {
          mint: token.tokenAddress,
          address: token.tokenAddress,
          // Use multiple sources for name and symbol
          name: tokenData.name || 
                pumpRankData?.name || 
                swaps24hData?.name || 
                token.name || 
                token.tokenName,
          symbol: tokenData.symbol || 
                 pumpRankData?.symbol || 
                 swaps24hData?.symbol || 
                 token.symbol || 
                 token.tokenSymbol,
          isBoosted: true,
          boost_count: token.amount || token.boostCount || 1,
          total_boosts: token.totalAmount || token.boostCount || 1,
          // Market data
          usd_market_cap: (() => {
            const dexMcap = tokenData.marketCap || 0;
            const jsonMcap = token.marketCap || 0;
            
            // If both exist, use the larger one as it's likely more recent
            if (dexMcap && jsonMcap) {
              return Math.max(dexMcap, jsonMcap);
            }
            // Otherwise use whichever exists
            return dexMcap || jsonMcap || 0;
          })(),
          volume_24h: tokenData.volume24h,
          swaps_24h: typeof tokenData.txns24h === 'object' 
            ? (tokenData.txns24h.buys || 0) + (tokenData.txns24h.sells || 0)
            : tokenData.txns24h || 0,
          price: tokenData.priceUsd,
          // Use consistent timestamp format
          created_timestamp: tokenData.pairCreatedAt || 0
        };
      });

      return boostedTokens;

    } catch (error) {
      console.error('Error fetching DEX data:', error);
      return solanaTokens;
    }
  } catch (error) {
    console.error('Error fetching boosted tokens:', error);
    return [];
  }
}

function enrichTokenData(token) {
  const enrichedData = { ...token };
  const tokenAddress = token.mint.toLowerCase();

  // Initialize market cap from token data
  let marketCap = parseFloat(token.usd_market_cap || token.market_cap || 0);

  // Check dex_paid.json data
  const dexScreenerData = dexPaidData.data.dexscreener_spents.find(
    t => t.base_address.toLowerCase() === tokenAddress
  );
  if (dexScreenerData?.base_token_info) {
    const dexMcap = parseFloat(dexScreenerData.base_token_info.market_cap || 0);
    // Update if dex market cap is more recent (larger)
    marketCap = Math.max(marketCap, dexMcap);
  }

  // Check pump_ranks.json data
  const pumpRankData = pumpRanks.data.pumps.find(
    t => t.address?.toLowerCase() === tokenAddress
  );
  if (pumpRankData) {
    // Check various market cap timeframes and use the most recent
    const mcap1m = parseFloat(pumpRankData.market_cap_1m || 0);
    const mcap5m = parseFloat(pumpRankData.market_cap_5m || 0);
    // Use the largest (most recent) value
    marketCap = Math.max(marketCap, mcap1m, mcap5m);
  }

  // Update the enriched data with the most recent market cap
  enrichedData.usd_market_cap = marketCap;
  enrichedData.market_cap = marketCap;

  // Check if token is in boosted tokens list
  const boostedToken = boostedTokens.find(t => t.mint.toLowerCase() === tokenAddress);
  if (boostedToken) {
    enrichedData.isBoosted = true;
    enrichedData.boost_count = boostedToken.amount;
    enrichedData.total_boosts = boostedToken.totalAmount;
  }

  // Check if token has boost data from transactions
  enrichedData.isBoosted = (enrichedData.swaps_1h || 0) > 0;
  enrichedData.boost_count = enrichedData.swaps_1h || 0;

  // Check dex_paid.json for basic info and social links
  const dexPaidToken = dexPaidData.data.dexscreener_spents.find(
    t => t.base_address.toLowerCase() === tokenAddress
  );
  if (dexPaidToken?.base_token_info) {
    enrichedData.isDexPaid = true; // Mark as DEX paid
    const info = dexPaidToken.base_token_info;
    enrichedData.volume = enrichedData.volume || parseFloat(info.volume) || 0;
    enrichedData.liquidity = enrichedData.liquidity || parseFloat(info.liquidity) || 0;
    enrichedData.holder_count = enrichedData.holder_count || parseInt(info.holder_count) || 0;
    enrichedData.price = enrichedData.price || parseFloat(info.price) || 0;
    enrichedData.mc = enrichedData.mc || parseFloat(info.market_cap) || 0;
    enrichedData.social_links = enrichedData.social_links || info.social_links;
    enrichedData.burn_ratio = info.burn_ratio;
    enrichedData.burn_status = info.burn_status;
    enrichedData.swaps = parseInt(info.swaps) || 0;
    enrichedData.buys = parseInt(info.buys) || 0;
    enrichedData.sells = parseInt(info.sells) || 0;
    enrichedData.price_change_percent1h = parseFloat(info.price_change_percent1h) || 0;
    enrichedData.price_change_percent24h = parseFloat(info.price_change_percent24h) || 0;
    enrichedData.creator_balance_rate = info.creator_balance_rate;
    enrichedData.creator_token_status = info.creator_token_status;
  }

  // Check burn status
  if (enrichedData.burn_status === 'burn') {
    enrichedData.isBurned = true;
  }

  // Check new_pair_ranks.json for ranking and trending info
  const rankedToken = newPairRanks.data?.pairs?.find(
    t => t.address.toLowerCase() === tokenAddress
  );
  if (rankedToken) {
    enrichedData.rank = enrichedData.rank || rankedToken.rank;
    enrichedData.score = enrichedData.score || rankedToken.score;
    enrichedData.trending = enrichedData.trending || rankedToken.trending;
    enrichedData.platform = rankedToken.platform;
    enrichedData.pool_type = rankedToken.pool_type;
    enrichedData.initial_liquidity = rankedToken.initial_liquidity;
    enrichedData.quote_reserve = rankedToken.quote_reserve;
    enrichedData.launchpad = rankedToken.launchpad;
  }

  // Fill in missing social links if available from any source
  if (!enrichedData.twitter && enrichedData.social_links?.twitter_username) {
    enrichedData.twitter = enrichedData.social_links.twitter_username;
  }
  if (!enrichedData.telegram && enrichedData.social_links?.telegram) {
    enrichedData.telegram = enrichedData.social_links.telegram;
  }
  if (!enrichedData.website && enrichedData.social_links?.website) {
    enrichedData.website = enrichedData.social_links.website;
  }

  // Add additional metadata
  enrichedData.metadata = {
    burn_ratio: enrichedData.burn_ratio,
    burn_status: enrichedData.burn_status,
    creator_balance_rate: enrichedData.creator_balance_rate,
    creator_token_status: enrichedData.creator_token_status,
    platform: enrichedData.platform,
    pool_type: enrichedData.pool_type,
    initial_liquidity: enrichedData.initial_liquidity,
    quote_reserve: enrichedData.quote_reserve,
    launchpad: enrichedData.launchpad,
    volume_1m: enrichedData.volume_1m,
    volume_5m: enrichedData.volume_5m,
    volume_1h: enrichedData.volume_1h,
    volume_6h: enrichedData.volume_6h,
    volume_24h: enrichedData.volume_24h,
    swaps_1m: enrichedData.swaps_1m,
    swaps_5m: enrichedData.swaps_5m,
    swaps_1h: enrichedData.swaps_1h,
    swaps_6h: enrichedData.swaps_6h,
    swaps_24h: enrichedData.swaps_24h,
    price_changes: {
      "1m": enrichedData.price_change_percent1m,
      "5m": enrichedData.price_change_percent5m,
      "1h": enrichedData.price_change_percent1h,
      "24h": enrichedData.price_change_percent24h
    },
    market_caps: {
      "1m": enrichedData.market_cap_1m,
      "5m": enrichedData.market_cap_5m
    },
    total_supply: enrichedData.total_supply,
    dev_token_burn_amount: enrichedData.dev_token_burn_amount,
    dev_token_burn_ratio: enrichedData.dev_token_burn_ratio,
    top_10_holder_rate: enrichedData.top_10_holder_rate,
    rat_trader_amount_rate: enrichedData.rat_trader_amount_rate
  };

  // Handle timestamps from different sources
  if (dexPaidToken?.open_timestamp) {
    enrichedData.created_at = formatTimestamp(dexPaidToken.open_timestamp);
  }
  if (rankedToken?.created_at) {
    enrichedData.created_at = enrichedData.created_at || formatTimestamp(rankedToken.created_at);
  }

  return enrichedData;
}

const processTokens = (tokens) => {
  if (!Array.isArray(tokens)) return [];
  return tokens.map(token => enrichTokenData(token));
};

function enrichKothData() {
  try {
    // Get KOTH token from kothData
    const kothToken = kothData;
    
    if (!kothToken?.mint) {
      console.error('Invalid KOTH token data:', kothToken);
      return [];
    }

    // Find matching data in pump_ranks for additional metrics
    const kothFromRanks = pumpRanks.data.pumps.find(
      p => p.address?.toLowerCase() === kothToken.mint.toLowerCase()
    );

    // Build enriched KOTH data
    const enrichedData = {
      mint: kothToken.mint,
      address: kothToken.mint, // Add CA/address
      name: kothToken.name,
      symbol: kothToken.symbol,
      logo: kothToken.image_uri,
      description: kothToken.description,
      twitter: kothToken.twitter,
      telegram: kothToken.telegram,
      website: kothToken.website,
      // Convert milliseconds to seconds for timestamps
      created_timestamp: Math.floor(kothToken.created_timestamp / 1000),
      king_of_the_hill_timestamp: Math.floor(kothToken.king_of_the_hill_timestamp / 1000),
      market_cap: parseFloat(kothToken.market_cap || 0),
      usd_market_cap: parseFloat(kothToken.usd_market_cap || 0),
      volume: parseFloat(kothFromRanks?.volume_24h || 0),
      volume_24h: parseFloat(kothFromRanks?.volume_24h || 0),
      swaps: parseInt(kothFromRanks?.swaps_24h || 0),
      swaps_24h: parseInt(kothFromRanks?.swaps_24h || 0),
      progress: kothToken.complete ? 1 : (kothToken.usd_market_cap / 82137),
      isKoth: true,
      id: `${kothToken.mint}-koth` // Unique ID for React key
    };

    return [enrichedData];

  } catch (error) {
    console.error('Error enriching KOTH data:', error);
    return [];
  }
}

export async function fetchTokenData() {
  const result = await checkAccessAndExecute(async () => {
    try {
      // Fetch boosted tokens first
      await fetchBoostedTokens();
      
      // Get enriched KOTH data
      const kothTokens = enrichKothData();
      
      // Process dex paid tokens
      const dexPaidTokens = (dexPaidData?.data?.dexscreener_spents || []).map(spent => ({
        mint: spent.base_address,
        address: spent.base_address,
        id: `${spent.base_address}-dex`,
        name: spent.base_token_info.name,
        symbol: spent.base_token_info.symbol,
        logo: spent.base_token_info.logo,
        market_cap: parseFloat(spent.base_token_info.market_cap || 0),
        usd_market_cap: parseFloat(spent.base_token_info.market_cap || 0),
        volume: parseFloat(spent.base_token_info.volume || 0),
        volume_24h: parseFloat(spent.base_token_info.volume || 0),
        swaps: parseInt(spent.base_token_info.swaps || 0),
        swaps_24h: parseInt(spent.base_token_info.swaps || 0),
        swaps_1h: parseInt(spent.base_token_info.swaps_1h || 0),
        twitter: spent.base_token_info.social_links?.twitter_username,
        telegram: spent.base_token_info.social_links?.telegram,
        website: spent.base_token_info.social_links?.website,
        created_timestamp: spent.open_timestamp,
        isDexPaid: true
      }));

      // Process pump ranks data
      const pumpTokens = (pumpRanks?.data?.pumps || []).map(pump => ({
        mint: pump.address,
        address: pump.address,
        id: `${pump.address}-${pump.burn_status === 'burn' ? 'burned' : 'pump'}`,
        name: pump.name,
        symbol: pump.symbol,
        logo: pump.logo,
        market_cap: parseFloat(pump.market_cap || 0),
        usd_market_cap: parseFloat(pump.usd_market_cap || 0),
        volume: parseFloat(pump.volume_24h || 0),
        volume_24h: parseFloat(pump.volume_24h || 0),
        swaps: parseInt(pump.swaps || 0),
        swaps_24h: parseInt(pump.swaps_24h || 0),
        swaps_1h: parseInt(pump.swaps_1h || 0),
        twitter: pump.twitter,
        telegram: pump.telegram,
        website: pump.website,
        created_timestamp: pump.created_timestamp,
        burn_status: pump.burn_status
      }));

      // Combine all tokens
      const allTokens = [...kothTokens];
      
      // Add boosted tokens first
      boostedTokens.forEach(token => {
        if (!allTokens.find(t => t.mint === token.mint)) {
          allTokens.push({
            ...token,
            isBoosted: true,
            boost_count: token.boost_count || 1,
            total_boosts: token.total_boosts || 1
          });
        }
      });
      
      // Add other tokens
      [...dexPaidTokens, ...pumpTokens].forEach(token => {
        const existingToken = allTokens.find(t => t.mint === token.mint);
        if (!existingToken) {
          // Check if token is in boosted list
          const boostedToken = boostedTokens.find(b => b.mint === token.mint);
          if (boostedToken) {
            allTokens.push({
              ...token,
              ...boostedToken,
              isBoosted: true,
              boost_count: boostedToken.boost_count || 1,
              total_boosts: boostedToken.total_boosts || 1
            });
          } else {
            allTokens.push(token);
          }
        }
      });

      const result = {
        tokens: allTokens,
        kothData: kothTokens[0] || null
      };

      cacheManager.write(result);
      return result;

    } catch (error) {
      console.error('Error fetching token data:', error);
      const cachedData = cacheManager.read();
      return cachedData?.data || { tokens: [], kothData: null };
    }
  });

  return result?.accessRequired ? { tokens: [], kothData: null } : result;
}

function formatTimestamp(timestamp) {
  if (!timestamp) return Math.floor(Date.now() / 1000);
  
  try {
    // If it's already a Unix timestamp (number), just return it
    if (typeof timestamp === 'number') {
      return timestamp;
    }

    // Handle "M/D/YYYY, H:MM:SS AM/PM" format
    if (typeof timestamp === 'string') {
      if (timestamp.includes(',')) {
        const [datePart, timePart] = timestamp.split(',');
        const [month, day, year] = datePart.trim().split('/');
        const [time, period] = timePart.trim().split(' ');
        const [hours, minutes, seconds] = time.split(':');
        
        // Convert 12-hour to 24-hour format if needed
        let hour = parseInt(hours);
        if (period === 'PM' && hour !== 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;

        const date = new Date(2024, month - 1, day, hour, parseInt(minutes), parseInt(seconds));
        return Math.floor(date.getTime() / 1000);
      }
      
      // For any other string format
      const date = new Date(timestamp);
      return Math.floor(date.getTime() / 1000);
    }
    
    return Math.floor(Date.now() / 1000);
  } catch (error) {
    console.error('Error formatting timestamp:', error);
    return Math.floor(Date.now() / 1000);
  }
}

function calculateChange(priceChange) {
  if (!priceChange) return 0;
  return parseFloat(priceChange) * 100;
}

export const categorizeTokens = (tokens, timeframe = '1m') => {
  let trendingData;
  
  // Select data based on timeframe
  switch(timeframe) {
    case '24h':
      trendingData = swaps24h.data.rank;
      break;
    case '6h':
      trendingData = swaps6h.data.rank;
      break;
    case '5m':
      trendingData = swaps5m.data.rank;
      break;
    case '1m':
    default:
      trendingData = swaps1m.data.rank;
      break;
  }

  // Filter DEX paid tokens
  const dexPaidTokens = tokens.filter(token => {
    const isDexPaid = token.isDexPaid || 
                     token.dexscr_ad === 1 || 
                     (dexPaidData?.data?.dexscreener_spents || []).some(
                       t => t.base_address?.toLowerCase() === token.mint?.toLowerCase()
                     );
    return isDexPaid;
  });

  // Filter boosted tokens and ensure timestamps are correct
  const boostedTokens = tokens.filter(token => {
    if (!token.boost_count || token.boost_count === 0) return false;

    // Get timestamp from boost data if available
    const boostData = pumpRanks.data?.pumps?.find(
      t => t.address?.toLowerCase() === token.mint?.toLowerCase()
    );

    if (boostData) {
      token.boost_timestamp = boostData.boost_timestamp || boostData.created_timestamp;
      token.created_timestamp = boostData.created_timestamp;
      token.pool_creation_timestamp = boostData.pool_creation_timestamp;
    }

    // Get additional data from other sources
    const dexPaidInfo = dexPaidData?.data?.dexscreener_spents?.find(
      t => t.base_address?.toLowerCase() === token.mint?.toLowerCase()
    );

    if (dexPaidInfo) {
      token.open_timestamp = dexPaidInfo.open_timestamp;
    }

    // Get data from swaps files
    const swapsData = swaps24h.data?.rank?.find(
      t => t.address?.toLowerCase() === token.mint?.toLowerCase()
    );

    if (swapsData) {
      token.last_trade_timestamp = swapsData.last_trade_timestamp;
    }

    return true;
  });

  return {
    dexPaid: dexPaidTokens || [],
    boosted: boostedTokens || [],
    trending: trendingData || [],
    koth: tokens.find(token => token.isKOTH) || null
  };
};

// Add function to get trending data for specific timeframe
export const getTrendingData = (timeframe) => {
  switch(timeframe) {
    case '24h':
      return swaps24h.data.rank;
    case '6h':
      return swaps6h.data.rank;
    case '5m':
      return swaps5m.data.rank;
    case '1m':
    default:
      return swaps1m.data.rank;
  }
};

export function initializeApp() {
  const result = checkAccessAndExecute(() => {
    // Start fetching market caps periodically
    fetchTokenData();
    setInterval(fetchTokenData, 60000); // Refresh every minute
    return { success: true };
  });

  return result; // Return the result without throwing
}
