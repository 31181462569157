import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { PaymentWall } from './components/PaymentWall';
import { sellixManager } from './utils/sellixManager';

const Root = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // Check if user has valid access stored
        const hasAccess = await sellixManager.validateStoredAccess();
        setIsVerified(hasAccess);
      } catch (err) {
        console.error('Error checking access:', err);
      } finally {
        setIsChecking(false);
      }
    };

    checkAccess();
  }, []);

  const handleVerification = () => {
    setIsVerified(true);
  };

  if (isChecking) {
    return null; // Or loading spinner
  }

  return (
    <React.StrictMode>
      {!isVerified ? (
        <PaymentWall onAccess={handleVerification} />
      ) : (
        <App />
      )}
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
