export const copyToClipboard = (text, setCopiedFeedback) => {
  navigator.clipboard.writeText(text).then(() => {
    if (setCopiedFeedback) {
      setCopiedFeedback(text);
      setTimeout(() => setCopiedFeedback(null), 2000);
    }
  }, (err) => {
    console.error('Could not copy text: ', err);
  });
};

export const formatNumber = (num) => {
  if (!num) return '0';
  if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
  if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
  if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
  return parseFloat(num).toFixed(1);
};