import React, { useState } from 'react';
import TokenCategory from './TokenCategory';
import { Search, TrendingUp, DollarSign, Rocket } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCrown as faCrownOld } from '@fortawesome/free-solid-svg-icons/faCrown';


// Add icons to library
library.add(faCrown, faChartLine);

const MobileTabs = ({ 
  categories, 
  currentTab, 
  setCurrentTab, 
  darkMode, 
  searchTerm, 
  setSearchTerm,
  showAnimations,
  setShowAnimations,
  layoutSettings,
  setLayoutSettings,
  tokens,
  kothHistory
}) => {
  const [showSearch, setShowSearch] = useState(false);

  const mobileCategories = [
    {
      title: 'KOTH',
      icon: <FontAwesomeIcon icon="crown" />,
      component: <TokenCategory 
        title="Kings of The Hill"
        icon={<FontAwesomeIcon icon="crown" />}
        tokens={kothHistory}
        darkMode={darkMode} 
        isKOTH={true}
        searchTerm={searchTerm}
        layoutSettings={layoutSettings}
      />
    },

    {
      title: 'DEX', 
      icon: <DollarSign size={20}/>,
      component: <TokenCategory 
        title="DEX Paid"
        icon={<DollarSign size={20}/>}
        tokens={tokens?.dexPaid || []}
        darkMode={darkMode}
      />
    },
    {
      title: 'Boosted',
      icon: <Rocket size={20}/>,
      component: <TokenCategory 
        title="Boosted"
        icon={<Rocket size={20}/>}
        tokens={tokens?.boosted || []}
        darkMode={darkMode}
      />
    },
    {
      title: 'Trending',
      icon: <TrendingUp size={20}/>,
      component: <TokenCategory 
        title="Trending"
        icon={<TrendingUp size={20}/>}
        tokens={tokens?.trending || []}
        darkMode={darkMode}
      />
    }
  ];

  return (
    <div className="min-h-screen dark:bg-[#13141A] bg-[#F7F7F7] pb-16">
      {/* Header */}
      <div className="sticky top-0 z-50 bg-inherit px-4 py-3 border-b dark:border-gray-800">
        <div className="flex items-center justify-between">
          <h1 className="text-xl font-bold">
            <span className="dark:text-white text-gray-900">Stalk</span>
            <span className="text-yellow-400">Fun</span>
          </h1>
          <button 
            onClick={() => setShowSearch(!showSearch)}
            className="w-8 h-8 flex items-center justify-center text-gray-500 dark:text-gray-400"
          >
            <Search size={20} />
          </button>
        </div>

        {/* Search Input - Slides down when active */}
        {showSearch && (
          <div className="mt-2 transition-all duration-200 ease-in-out">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search tokens..."
              className="w-full px-4 py-2 rounded-xl bg-[#2a2b36] text-white border border-gray-700
                focus:outline-none focus:border-[#00ff9d]"
            />
          </div>
        )}
      </div>

      {/* Content Area */}
      <div className="px-4 py-2">
        {mobileCategories[currentTab]?.component}
      </div>

      {/* Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-[#1c1d25] border-t border-gray-800">
        <div className="flex justify-around">
          {mobileCategories.map((category, index) => (
            <button
              key={category.title}
              onClick={() => setCurrentTab(index)}
              className={`flex-1 py-3 px-2 flex flex-col items-center justify-center ${
                currentTab === index
                  ? 'text-[#00ff9d]'
                  : 'text-gray-400'
              }`}
            >
              {category.icon}
              <span className="text-xs mt-1">{category.title}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileTabs;
