import React from 'react';

const HowItWorks = () => {
  return (
    <div className="min-h-screen bg-[#13141A] text-white py-16">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-[#00ff9d]">How It Works</h1>
        
        <div className="space-y-8">
          <section className="bg-[#1c1d25] p-6 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">Token Categories</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">👑 King of The Hill</h3>
                <p className="text-gray-300">Top performing tokens that maintain their position through consistent volume and community engagement.</p>
              </div>
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">🚀 Boosted</h3>
                <p className="text-gray-300">Tokens that have received community boosts, indicating strong community support and potential.</p>
              </div>
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">📈 Trending</h3>
                <p className="text-gray-300">Currently hot tokens based on real-time market activity and trading volume.</p>
              </div>
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">💎 DEX Paid</h3>
                <p className="text-gray-300">Verified tokens that have completed the DEX payment process, showing commitment to their project.</p>
              </div>
            </div>
          </section>

          <section className="bg-[#1c1d25] p-6 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">Features</h2>
            <ul className="space-y-3 text-gray-300">
              <li>• Real-time price and volume tracking</li>
              <li>• Community-driven token boosting system</li>
              <li>• Multiple timeframe analysis (1m, 5m, 6h, 24h)</li>
              <li>• Advanced sorting and filtering options</li>
              <li>• Mobile-optimized interface</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks; 