import React from 'react';

const Support = () => {
  return (
    <div className="min-h-screen bg-[#13141A] text-white py-16">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-4xl font-bold mb-8 text-[#00ff9d]">Support</h1>
        
        <div className="space-y-8">
          <section className="bg-[#1c1d25] p-6 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">Contact Us</h2>
            <div className="space-y-4 text-gray-300">
              <p>For immediate support, reach out through any of these channels:</p>
              <div className="space-y-2">
                <a 
                  href="https://t.me/pumpfun" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-[#00ff9d] hover:underline"
                >
                  <span>📱</span> Telegram Community
                </a>
                <a 
                  href="https://twitter.com/pumpfun" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 text-[#00ff9d] hover:underline"
                >
                  <span>🐦</span> Twitter Support
                </a>
                <a 
                  href="mailto:support@pumpfun.xyz" 
                  className="flex items-center gap-2 text-[#00ff9d] hover:underline"
                >
                  <span>📧</span> Email Support
                </a>
              </div>
            </div>
          </section>

          <section className="bg-[#1c1d25] p-6 rounded-xl">
            <h2 className="text-2xl font-bold mb-4">FAQ</h2>
            <div className="space-y-4">
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">How do I boost a token?</h3>
                <p className="text-gray-300">Connect your wallet and use the boost button on any token card to show your support.</p>
              </div>
              <div>
                <h3 className="text-xl text-[#00ff9d] mb-2">What makes a token DEX paid?</h3>
                <p className="text-gray-300">Tokens become DEX paid after completing the verification process through our platform.</p>
              </div>
              {/* Add more FAQ items as needed */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Support; 