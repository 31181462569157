import axios from 'axios';
import proxies from './Scraper/proxies.json';

class ProxyManager {
  constructor() {
    this.proxies = proxies.proxies;
    this.currentIndex = 0;
    this.failedProxies = new Set();
  }

  getNextProxy() {
    while (this.currentIndex < this.proxies.length) {
      const proxy = this.proxies[this.currentIndex];
      this.currentIndex = (this.currentIndex + 1) % this.proxies.length;
      
      if (!this.failedProxies.has(proxy.ip)) {
        return {
          host: proxy.ip,
          port: proxy.port,
          auth: {
            username: proxy.username,
            password: proxy.password
          }
        };
      }
    }
    
    // If all proxies failed, reset and try again
    this.failedProxies.clear();
    this.currentIndex = 0;
    return this.getNextProxy();
  }

  markProxyAsFailed(proxyIp) {
    this.failedProxies.add(proxyIp);
  }

  getAxiosInstance() {
    const proxyConfig = this.getNextProxy();
    return axios.create({
      proxy: {
        host: proxyConfig.host,
        port: proxyConfig.port,
        auth: {
          username: proxyConfig.auth.username,
          password: proxyConfig.auth.password,
        }
      }
    });
  }
}

export const proxyManager = new ProxyManager();