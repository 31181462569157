// Simple in-memory cache implementation
let cache = {
  data: null,
  timestamp: null
};

const CACHE_DURATION = 60 * 1000; // 60 seconds

export const cacheManager = {
  write: (data) => {
    cache = {
      data,
      timestamp: Date.now()
    };
  },

  read: () => {
    if (!cache.data || !cache.timestamp) {
      return null;
    }

    // Check if cache has expired
    if (Date.now() - cache.timestamp > CACHE_DURATION) {
      return null;
    }

    return cache;
  },

  clear: () => {
    cache = {
      data: null, 
      timestamp: null
    };
  }
};
