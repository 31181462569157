import React from 'react';
import { formatNumber } from '../utils/misc';
import { FaTwitter, FaTelegram, FaGlobe, FaCheckCircle } from 'react-icons/fa';

export const TokenCard = ({ token, isKOTH = false, kothRank = 1, darkMode, firstSeen, lastUpdated, timeframe }) => {
  if (!token) return null;

  // Format timestamp to readable date - use multiple sources for timestamp
  const formatDate = (timestamp) => {
    // Get the boost timestamp first if it exists
    const time = token.boost_timestamp || 
                 token.last_boost_timestamp ||
                 token.created_timestamp || 
                 token.pool_creation_timestamp || 
                 token.open_timestamp || 
                 token.last_trade_timestamp;
                 
    if (!time) return 'Just Listed';
    
    // Convert milliseconds to seconds if needed
    const normalizedTime = time > 1700000000000 ? Math.floor(time / 1000) : time;
    
    const date = new Date(normalizedTime * 1000);
    return date.toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
  };

  // Determine if token is boosted based on transactions in last hour
  const hasBoosts = (token.boost_count || 0) > 0;

  // Format transactions count - handle both number and object formats
  const formatTransactions = (txns) => {
    if (typeof txns === 'number') return txns;
    if (typeof txns === 'object' && txns !== null) {
      return ((txns.buys || 0) + (txns.sells || 0));
    }
    return 0;
  };

  // Update the transactions display
  const displayTransactions = formatTransactions(token.swaps_24h || token.txns_24h || token.transactions_24h || 0);

  // Add trending-specific display
  const getTrendingStats = () => {
    if (!timeframe) return null;
    
    const volume = token[`volume_${timeframe}`] || token.volume || 0;
    const priceChange = token[`price_change_percent${timeframe}`] || token.price_change_percent || 0;
    
    return (
      <div className="bg-gradient-to-r from-purple-500/10 to-purple-500/20 px-3 py-1.5 text-center">
        <div className="flex items-center justify-center gap-2">
          <span className="text-purple-500 text-md font-bold flex items-center gap-1">
            <span className="text-lg">📈</span>
            <span>{priceChange.toFixed(2)}%</span>
          </span>
          <span className="text-purple-500/80 text-sm">
            {timeframe.toUpperCase()} Change
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={`
        w-full rounded-2xl overflow-hidden 
        dark:bg-[#1c1d25] bg-white/70 dark:border-[#2e2f3b] border-gray-200/50
        border shadow-sm backdrop-blur-sm
        ${isKOTH ? 'border-yellow-400' : ''}
        ${hasBoosts ? 'border-[#00ff9d]' : ''}
      `}>
      {isKOTH && (
        <div className="bg-yellow-400 px-3 py-1.5 text-center relative">
          <span className="text-black text-md">
            <span className="shadow-sm">👑</span> 
            King of The Hill #{kothRank}
          </span>
          {token.isDexPaid && (
            <span className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-1 text-green-800">
              <FaCheckCircle className="text-sm" />
              <span className="text-xs font-medium">DEX</span>
            </span>
          )}
        </div>
      )}

      {token.boost_count > 0 && (
        <div className={`px-3 py-1.5 text-center
          ${darkMode
            ? 'bg-gradient-to-r from-[#00ff9d]/10 to-[#00ff9d]/20'
            : 'bg-gradient-to-r from-green-900 via-green-800 to-green-900'}`}>
          <div className="flex items-center justify-center gap-2">
            <span className="text-[#00ff9d] text-md font-bold flex items-center gap-1">
              <span className="text-lg">🚀</span>
              <span>{token.boost_count}</span>
            </span>
            <span className="text-[#00ff9d]/80 text-sm">
              {parseInt(token.boost_count) === 1 ? 'Boost' : 'Boosts'}
            </span>
          </div>
        </div>
      )}

      {token.burn_status === 'burn' && (
        <div className="bg-gradient-to-r from-red-500/10 to-red-500/20 px-3 py-1.5 text-center border-b border-red-500/20">
          <div className="flex items-center justify-center gap-2">
            <span className="text-red-500 text-md font-bold flex items-center gap-1">
              <span className="text-lg">🔥</span>
              <span>Burned</span>
            </span>
          </div>
        </div>
      )}

      <div className="p-3 space-y-2">
        <div className="flex flex-row justify-between">
          {/* Token Image */}
          <div className="aspect-square w-1/2 rounded-2xl overflow-hidden">
            <img
              src={token.logo || `https://image.bullx.io/1399811149/${token.mint || ''}`}
              alt={token.symbol || 'Token'}
              className="w-full h-full object-cover hover:opacity-90 transition-opacity cursor-pointer"
              onError={(e) => {
                e.target.onerror = null;
                // Use placeholder with token symbol
                e.target.src = `https://placehold.co/200x200/1c1d25/00ff9d?text=${token.symbol || 'Token'}`;
              }}
            />
          </div>

          <div className="w-1/2 space-y-2 items-center justify-center px-1 truncate">
            <span className={`text-[13px] py-1 font-bold text-center w-full block
              ${darkMode ? 'text-white' : 'text-gray-700'}`}>
              {formatDate(token.created_timestamp)}
            </span>

            <div className="mx-auto flex-col flex text-center w-fit">
              <span className={`text-lg font-bold ${darkMode ? 'text-white' : 'text-gray-800'}`}>
                {token.symbol || 'Unknown'}
              </span>
              <span className={`text-xs ${darkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                {token.name || 'Unknown'}
              </span>
            </div>

            {/* Social Links */}
            <div className="flex items-center justify-center gap-3 pt-1">
              {token.twitter && (
                <a href={token.twitter.startsWith('http') ? token.twitter : `https://twitter.com/${token.twitter}`} 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-gray-900'}`}>
                  <FaTwitter />
                </a>
              )}
              {token.telegram && (
                <a href={token.telegram.startsWith('http') ? token.telegram : `https://t.me/${token.telegram}`} 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-gray-900'}`}>
                  <FaTelegram />
                </a>
              )}
              {token.website && (
                <a href={token.website} 
                   target="_blank" 
                   rel="noopener noreferrer"
                   className={`text-lg ${darkMode ? 'text-gray-400 hover:text-white' : 'text-gray-500 hover:text-gray-900'}`}>
                  <FaGlobe />
                </a>
              )}
            </div>
          </div>
        </div>

        {/* Market Stats with updated styling */}
        <div className="flex items-center justify-between">
          <span className={`text-md font-bold ${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
            Market Cap
          </span>
          <span className={`text-sm font-bold px-2 py-0.5 rounded 
            ${darkMode 
              ? 'bg-[#00ff9d]/10 text-[#00ff9d]' 
              : 'bg-gray-800 text-[#00ff9d]'}`}>
            ${formatNumber(token.usd_market_cap || 0)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className={`text-md font-bold ${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
            Volume (24hr)
          </span>
          <span className={`text-sm font-bold px-2 py-0.5 rounded 
            ${darkMode 
              ? 'bg-[#00ff9d]/10 text-[#00ff9d]' 
              : 'bg-gray-800 text-[#00ff9d]'}`}>
            ${formatNumber(token.volume_24h || 0)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className={`text-md font-bold ${darkMode ? 'text-gray-400' : 'text-gray-900'}`}>
            Transactions (24hr)
          </span>
          <span className={`text-sm font-bold px-2 py-0.5 rounded 
            ${darkMode 
              ? 'bg-[#00ff9d]/10 text-[#00ff9d]' 
              : 'bg-gray-800 text-[#00ff9d]'}`}>
            {displayTransactions}
          </span>
        </div>

        {/* Buy Button with gradient */}
        <a
          href={token.address ? `https://bullx.io/terminal?chainId=1399811149&address=${token.address}&r=GY21GEJFAG7` : '#'}
          target="_blank"
          rel="noopener noreferrer"
          className={`block w-full py-2 text-center font-bold rounded-lg text-md
            ${darkMode 
              ? 'dark:bg-[#00ff9d]/20 text-[#00ff9d] dark:hover:bg-[#00ff9d]/30' 
              : 'bg-gradient-to-r from-green-900 via-green-800 to-green-900 hover:from-green-800 hover:via-green-700 hover:to-green-800 text-[#00ff9d] transition-all duration-300'}`}
        >
          Buy
        </a>
      </div>

      {isKOTH && firstSeen && (
        <div className="px-3 pb-3 text-xs text-gray-400">
          <div>First Seen: {firstSeen}</div>
          <div>Last Updated: {lastUpdated}</div>
        </div>
      )}

      {timeframe && getTrendingStats()}
    </div>
  );
};