import React, { useEffect, useState } from 'react';
import { FaLock, FaCrown, FaCheckCircle } from 'react-icons/fa';
import { sellixManager } from '../utils/sellixManager';

const DEV_ACCESS_KEY = 'stalkfun_dev_access';
const PAYMENT_URL = 'https://stalkfun.mysellix.io/product/67247dc8e1fe5';

export const PaymentWall = ({ onAccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [email, setEmail] = useState('');

  const handlePurchaseClick = (e) => {
    e.preventDefault();
    window.location.href = PAYMENT_URL;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const status = await sellixManager.getSubscriptionStatus(email);
      if (status.isActive) {
        // Store the access info
        localStorage.setItem('stalkfun_access', JSON.stringify({
          email,
          expiresAt: status.expiresAt
        }));
        onAccess();
      } else {
        setError('No active subscription found for this email');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('Failed to verify subscription');
    } finally {
      setIsLoading(false);
    }
  };

  const features = [
    { icon: '👑', text: 'Access to all token data' },
    { icon: '📊', text: 'Real-time market updates' },
    { icon: '🚀', text: 'Track boosted tokens' },
    { icon: '💰', text: 'Monitor DEX paid tokens' },
    { icon: '📈', text: 'Price change alerts' },
    { icon: '🔔', text: 'Custom notifications' }
  ];

  // Dev access handler
  const handleDevAccess = async (e) => {
    if (e.ctrlKey && e.shiftKey && e.key === 'D') {
      const password = prompt('Enter dev password:');
      if (password && sellixManager.checkDevAccess(password)) {
        localStorage.setItem(DEV_ACCESS_KEY, password);
        onAccess();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleDevAccess);
    return () => window.removeEventListener('keydown', handleDevAccess);
  }, [onAccess]);

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center z-50 p-4">
      <div className="bg-[#1c1d25] rounded-2xl max-w-md w-full overflow-hidden">
        {/* Header */}
        <div className="bg-gradient-to-r from-[#4f46e5] to-[#ec4899] p-6 text-center">
          <FaCrown className="text-4xl text-white mb-2 mx-auto" />
          <h2 className="text-2xl font-bold text-white mb-2">
            {isLoginMode ? 'Login' : 'Access Required'}
          </h2>
          <p className="text-white/80">
            {isLoginMode 
              ? 'Enter your email to access premium features' 
              : 'Unlock premium features with Stalk.fun subscription'}
          </p>
        </div>

        <div className="p-6">
          {isLoginMode ? (
            // Login Form
            <form onSubmit={handleLogin} className="space-y-4">
              <div>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full p-3 rounded-xl bg-[#2a2b36] text-white border border-gray-700
                    focus:outline-none focus:border-[#00ff9d]"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full py-3 px-4 rounded-xl bg-[#00ff9d] text-black font-medium
                  hover:bg-[#00ff9d]/90 transition-colors"
                disabled={isLoading}
              >
                {isLoading ? 'Verifying...' : 'Login'}
              </button>
              <button
                type="button"
                onClick={() => setIsLoginMode(false)}
                className="w-full py-2 text-gray-400 hover:text-white transition-colors"
              >
                Need to purchase? Click here
              </button>
            </form>
          ) : (
            // Purchase View
            <>
              <div className="grid grid-cols-2 gap-4 mb-6">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <span className="text-xl">{feature.icon}</span>
                    <span className="text-sm text-gray-300">{feature.text}</span>
                  </div>
                ))}
              </div>

              <div className="text-center mb-6">
                <div className="text-3xl font-bold text-white">
                  $49.00
                  <span className="text-sm text-gray-400 ml-2">/month</span>
                </div>
              </div>

              <button
                onClick={handlePurchaseClick}
                className="w-full py-3 px-4 rounded-xl bg-[#00ff9d] text-black font-medium
                  hover:bg-[#00ff9d]/90 transition-colors mb-3"
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Purchase Access'}
              </button>

              <button
                onClick={() => setIsLoginMode(true)}
                className="w-full py-2 text-gray-400 hover:text-white transition-colors"
              >
                Already purchased? Login here
              </button>
            </>
          )}

          {error && (
            <div className="mt-4 text-red-500 text-sm text-center">
              {error}
            </div>
          )}

          <div className="mt-4 text-center text-xs text-gray-500">
            By continuing, you agree to our Terms of Service
          </div>
        </div>
      </div>
    </div>
  );
};    