import { formatNumber } from '../utils/misc';

export const MobileTokenCard = ({ token }) => {
  const hasBoosts = token.boost_count > 0;

  return (
    <div className="p-4 border-b border-[#2e2f3b] bg-[#1c1d25]/50">
      {/* Boost Banner */}
      {hasBoosts && (
        <div className="mb-3 -mx-4 -mt-4 px-4 py-2 bg-gradient-to-r from-[#00ff9d]/10 to-[#00ff9d]/20 border-b border-[#00ff9d]/20">
          <div className="flex items-center justify-center gap-2">
            <span className="text-[#00ff9d] text-md font-bold flex items-center gap-1">
              <span className="text-lg">🚀</span>
              <span>{token.boost_count}</span>
            </span>
            <span className="text-[#00ff9d]/80 text-sm">
              {token.boost_count === 1 ? 'Boost' : 'Boosts'}
            </span>
          </div>
        </div>
      )}

      {/* Token Info */}
      <div className="flex gap-3">
        <div className="w-16 h-16 rounded-xl overflow-hidden flex-shrink-0 bg-[#2e2f3b]/50">
          <img 
            src={token.logo || `https://image.bullx.io/1399811149/${token.mint}`}
            alt={token.symbol}
            className="w-full h-full object-cover"
          />
        </div>

        <div className="flex-1 min-w-0">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="font-bold text-white truncate">{token.symbol}</h3>
              <p className="text-sm text-gray-400 truncate">{token.name}</p>
            </div>
            <div className="text-right">
              <div className="text-sm font-medium text-white">
                ${formatNumber(token.price || 0)}
              </div>
              {token.price_change_24h && (
                <div className={`text-xs ${token.price_change_24h >= 0 ? 'text-[#00ff9d]' : 'text-red-500'}`}>
                  {token.price_change_24h >= 0 ? '+' : ''}{token.price_change_24h.toFixed(2)}%
                </div>
              )}
            </div>
          </div>

          {/* Stats */}
          <div className="mt-3 grid grid-cols-3 gap-2">
            <div className="bg-[#2e2f3b]/30 rounded-lg p-2">
              <div className="text-xs text-gray-400">Market Cap</div>
              <div className="text-sm font-medium text-white">${formatNumber(token.market_cap || 0)}</div>
            </div>
            <div className="bg-[#2e2f3b]/30 rounded-lg p-2">
              <div className="text-xs text-gray-400">Volume (24h)</div>
              <div className="text-sm font-medium text-white">${formatNumber(token.volume_24h || 0)}</div>
            </div>
            <div className="bg-[#2e2f3b]/30 rounded-lg p-2">
              <div className="text-xs text-gray-400">Transactions</div>
              <div className="text-sm font-medium text-white">{formatNumber(token.swaps_24h || 0)}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Buy Button */}
      <a 
        href={`https://bullx.io/terminal?chainId=1399811149&address=${token.mint}&r=GY21GEJFAG7`}
        target="_blank"
        rel="noopener noreferrer"
        className="block w-full mt-4 py-2.5 text-center rounded-xl bg-[#00ff9d]/20 text-[#00ff9d] font-medium
          hover:bg-[#00ff9d]/30 transition-colors"
      >
        Buy
      </a>
    </div>
  );
};