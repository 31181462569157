/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { fetchTokenData, categorizeTokens, fetchMarketCaps, initializeApp } from './utils/dataFetcher';
import { cacheManager } from './utils/cacheManager';
import { TokenCard } from './components/TokenCard';
import { Header } from './components/Header';
import { SearchBar } from './components/SearchBar';
import TokenCategory from './components/TokenCategory';
import MobileTabs from './components/MobileTabs';
import { Crown, DollarSign, Rocket, TrendingUp } from 'lucide-react';
import HowItWorks from './components/pages/HowItWorks';
import Support from './components/pages/Support';
import { useRouter } from './utils/router';

function App() {
  const [tokens, setTokens] = useState({ dexPaid: [], boosted: [], burned: [] });
  const [kothHistory, setKothHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [error, setError] = useState(null);
  const [showAnimations, setShowAnimations] = useState(true);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);
  const [dexPaidDropdownOpen, setDexPaidDropdownOpen] = useState(false);
  const [boostedDropdownOpen, setBoostedDropdownOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [copiedFeedback, setCopiedFeedback] = useState(null);
  const [darkMode, setDarkMode] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const tokensRef = useRef(tokens);
  const kothTokensRef = useRef(kothHistory);
  const [layoutSettings, setLayoutSettings] = useState({
    columns: '4-col',
    order: ['koth', 'dexPaid', 'boosted', 'trending']
  });
  const [isMobile] = useState(window.innerWidth <= 768);
  const { currentPage, navigate } = useRouter();

  const fetchFullData = useCallback(async (retryCount = 0) => {
    try {
      const result = await fetchTokenData();
      
      if (result?.accessRequired) {
        setIsLoading(false);
        return;
      }

      setTokens(prevTokens => {
        const newTokens = categorizeTokens(result.tokens);
        tokensRef.current = newTokens;
        return newTokens;
      });

      setKothHistory(prevHistory => {
        if (!result.kothData) return prevHistory;
        
        const currentKoth = result.kothData;
        const existingIndex = prevHistory.findIndex(k => k.mint === currentKoth.mint);
        
        if (existingIndex >= 0) {
          const updatedHistory = [...prevHistory];
          updatedHistory[existingIndex] = {
            ...updatedHistory[existingIndex],
            ...currentKoth,
            lastUpdated: Date.now()
          };
          return updatedHistory;
        } else {
          return [{
            ...currentKoth,
            firstSeen: Date.now(),
            lastUpdated: Date.now()
          }, ...prevHistory];
        }
      });

      setError(null);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      const nextRetry = retryCount + 1;
      const delay = Math.min(1000 * 2 ** nextRetry, 120000);
      setTimeout(() => fetchFullData(nextRetry), delay);
    }
  }, []);

  useEffect(() => {
    initializeApp();
    fetchFullData();
    const fullDataInterval = setInterval(fetchFullData, 60000);
    
    return () => {
      clearInterval(fullDataInterval);
      if (cacheManager) {
        cacheManager.clear();
      }
    };
  }, [fetchFullData]);

  const mergeTokens = (oldTokens, newTokens) => {
    return newTokens.map(newToken => {
      const oldToken = oldTokens.find(t => t.mint === newToken.mint);
      return oldToken ? { ...oldToken, ...newToken } : newToken;
    });
  };

  const filterTokens = useCallback((tokens, term) => {
    if (!term.trim()) return tokens;
    
    const searchLower = term.toLowerCase();
    return {
      koth: tokens.koth ? [tokens.koth].filter(token => 
        token?.symbol?.toLowerCase().includes(searchLower) ||
        token?.name?.toLowerCase().includes(searchLower) ||
        token?.mint?.toLowerCase().includes(searchLower)
      ) : [],
      dexPaid: tokens.dexPaid.filter(token => 
        token.symbol?.toLowerCase().includes(searchLower) ||
        token.name?.toLowerCase().includes(searchLower) ||
        token.mint?.toLowerCase().includes(searchLower)
      ),
      boosted: tokens.boosted.filter(token => 
        token.symbol?.toLowerCase().includes(searchLower) ||
        token.name?.toLowerCase().includes(searchLower) ||
        token.mint?.toLowerCase().includes(searchLower)
      ),
      trending: tokens.trending.filter(token => 
        token.symbol?.toLowerCase().includes(searchLower) ||
        token.name?.toLowerCase().includes(searchLower) ||
        token.mint?.toLowerCase().includes(searchLower)
      )
    };
  }, []);

  const filteredTokens = useMemo(() => 
    filterTokens(tokens, searchTerm),
    [tokens, searchTerm, filterTokens]
  );

  const categories = [
    {
      title: 'KOTH',
      icon: <Crown size={20}/>,
      component: <TokenCategory 
        title="King of The Hill" 
        icon="👑" 
        tokens={tokens.koth ? [tokens.koth] : []} 
        darkMode={darkMode} 
        isKOTH={true}
        searchTerm={searchTerm}
      />
    },
    {
      title: 'DEX',
      icon: <DollarSign size={20}/>,
      component: <TokenCategory 
        title="DEX Paid" 
        icon="💎" 
        tokens={tokens.dexPaid} 
        darkMode={darkMode}
      />
    },
    {
      title: 'Boosted',
      icon: <Rocket size={20}/>,
      component: <TokenCategory 
        title="Boosted" 
        icon="🚀" 
        tokens={tokens.boosted} 
        darkMode={darkMode}
      />
    },
    {
      title: 'Trending',
      icon: <TrendingUp size={20}/>,
      component: <TokenCategory 
        title="Trending" 
        icon="📈" 
        tokens={tokens.trending} 
        darkMode={darkMode}
      />
    }
  ];

  const renderPage = () => {
    switch(currentPage) {
      case 'how-it-works':
        return <HowItWorks />;
      case 'support':
        return <Support />;
      case 'twitter':
        window.location.href = 'https://twitter.com/pumpfun';
        return null;
      default:
        return (
          <div className={darkMode ? 'dark' : ''}>
            {isMobile ? (
              <MobileTabs 
                categories={categories}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                darkMode={darkMode}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                showAnimations={showAnimations}
                setShowAnimations={setShowAnimations}
                layoutSettings={layoutSettings}
                setLayoutSettings={setLayoutSettings}
                tokens={tokens}
                kothHistory={kothHistory}
              />
            ) : (
              <div className={`min-h-screen w-full ${darkMode ? 'bg-[#13141A] text-white' : 'bg-[#F7F7F7] text-gray-800'}`}>
                <div className="max-w-7xl mx-auto px-4 py-8">
                  <Header darkMode={darkMode} setDarkMode={setDarkMode} />
                  <SearchBar 
                    searchTerm={searchTerm} 
                    setSearchTerm={setSearchTerm} 
                    dropdownOpen={dropdownOpen} 
                    setDropdownOpen={setDropdownOpen} 
                    showAnimations={showAnimations} 
                    setShowAnimations={setShowAnimations}
                    layoutSettings={layoutSettings}
                    setLayoutSettings={setLayoutSettings}
                  />

                  <main className="max-w-7xl mx-auto px-4 py-6">
                    <div className={`grid grid-cols-1 md:grid-cols-2 ${
                      layoutSettings.columns === '3-col' 
                        ? 'lg:grid-cols-3' 
                        : 'lg:grid-cols-4'
                    } gap-6`}>
                      {layoutSettings.order.map(categoryId => {
                        switch(categoryId) {
                          case 'koth':
                            return (
                              <TokenCategory
                                key="koth"
                                title="Kings of The Hill"
                                icon={<Crown size={20} />}
                                tokens={kothHistory}
                                darkMode={darkMode}
                                filterDropdownOpen={filterDropdownOpen}
                                setFilterDropdownOpen={setFilterDropdownOpen}
                                layoutSettings={layoutSettings}
                                isKOTH={true}
                              />
                            );
                          case 'dexPaid':
                            return (
                              <TokenCategory
                                key="dexPaid"
                                title="DEX Paid"
                                icon={<DollarSign size={20} />}
                                tokens={filteredTokens.dexPaid}
                                darkMode={darkMode}
                                filterDropdownOpen={dexPaidDropdownOpen}
                                setFilterDropdownOpen={setDexPaidDropdownOpen}
                                layoutSettings={layoutSettings}
                              />
                            );
                          case 'boosted':
                            return (
                              <TokenCategory
                                key="boosted"
                                title="Boosted"
                                icon={<Rocket size={20} />}
                                tokens={filteredTokens.boosted}
                                darkMode={darkMode}
                                filterDropdownOpen={boostedDropdownOpen}
                                setFilterDropdownOpen={setBoostedDropdownOpen}
                                layoutSettings={layoutSettings}
                              />
                            );
                          case 'trending':
                            return (
                              <TokenCategory
                                key="trending"
                                title="Trending"
                                icon={<TrendingUp size={20} />}
                                tokens={filteredTokens.trending}
                                darkMode={darkMode}
                                filterDropdownOpen={filterDropdownOpen}
                                setFilterDropdownOpen={setFilterDropdownOpen}
                                layoutSettings={layoutSettings}
                              />
                            );
                          default:
                            return null;
                        }
                      })}
                    </div>
                  </main>
                </div>
              </div>
            )}
            <footer className="px-8 text-white h-24 flex items-center justify-center">
            </footer>
          </div>
        );
    }
  };

  return renderPage();
}

export default App;
