import React from 'react';
import { Search, Layout, Settings } from 'lucide-react';

export function SearchBar({ searchTerm, setSearchTerm, showAnimations, setShowAnimations, layoutSettings, setLayoutSettings }) {
  const [showLayoutSettings, setShowLayoutSettings] = React.useState(false);

  const layouts = [
    { id: '3-col', name: '3 Columns' },
    { id: '4-col', name: '4 Columns' }
  ];

  const categories = [
    { id: 'koth', name: 'King of The Hill' },
    { id: 'dexPaid', name: 'DEX Paid' },
    { id: 'boosted', name: 'Boosted' },
    { id: 'trending', name: 'Trending' } // Fixed id from 'burned' to 'trending'
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-4">
      <div className="flex flex-col sm:flex-row items-center gap-4">
        {/* Search Input */}
        <div className="relative flex-grow">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 dark:text-gray-500" />
          <input 
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search token by name, symbol, or address"
            className="w-full pl-10 pr-4 py-2 rounded-lg border dark:bg-[#1c1d25] dark:border-[#2e2f3b] bg-white border-gray-200
              focus:outline-none focus:ring-2 focus:ring-[#00ff9d]/50 dark:text-white"
          />
        </div>

        {/* Layout Settings Button */}
        <button
          onClick={() => setShowLayoutSettings(!showLayoutSettings)}
          className="p-2 rounded-lg dark:bg-[#1c1d25] dark:border-[#2e2f3b] bg-white border border-gray-200
            hover:bg-gray-50 dark:hover:bg-[#2e2f3b] transition-colors"
        >
          <Layout className="text-gray-400" size={20} />
        </button>

        {/* Animations Toggle */}
        <div className="flex items-center gap-2">
          <span className="text-sm dark:text-gray-400 text-gray-500">Show animations</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={showAnimations}
              onChange={(e) => setShowAnimations(e.target.checked)}
            />
            <div className="w-12 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:bg-[#00ff9d]"></div>
            <div className="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform peer-checked:translate-x-6"></div>
          </label>
        </div>
      </div>

      {/* Layout Settings Dropdown */}
      {showLayoutSettings && (
        <div className="mt-4 p-4 rounded-lg dark:bg-[#1c1d25] bg-white border dark:border-[#2e2f3b] border-gray-200">
          <div className="space-y-4">
            {/* Column Layout */}
            <div>
              <h3 className="text-sm font-medium dark:text-gray-300 mb-2">Layout</h3>
              <div className="flex gap-2">
                {layouts.map(layout => (
                  <button
                    key={layout.id}
                    onClick={() => setLayoutSettings(prev => ({ ...prev, columns: layout.id }))}
                    className={`px-3 py-1 rounded-lg text-sm ${
                      layoutSettings.columns === layout.id
                        ? 'bg-[#00ff9d]/20 text-[#00ff9d]'
                        : 'dark:bg-[#2e2f3b] bg-gray-100 dark:text-gray-400 text-gray-600'
                    }`}
                  >
                    {layout.name}
                  </button>
                ))}
              </div>
            </div>

            {/* Category Order */}
            <div>
              <h3 className="text-sm font-medium dark:text-gray-300 mb-2">Category Order</h3>
              <div className="grid grid-cols-2 gap-2">
                {categories.map((category, index) => (
                  <div key={category.id} className="flex items-center gap-2">
                    <select
                      value={layoutSettings.order.indexOf(category.id)}
                      onChange={(e) => {
                        const newOrder = [...layoutSettings.order];
                        const oldIndex = newOrder.indexOf(category.id);
                        const newIndex = parseInt(e.target.value);
                        newOrder.splice(oldIndex, 1);
                        newOrder.splice(newIndex, 0, category.id);
                        setLayoutSettings(prev => ({ ...prev, order: newOrder }));
                      }}
                      className="dark:bg-[#2e2f3b] bg-white border dark:border-[#2e2f3b] border-gray-200 rounded px-2 py-1"
                    >
                      {[0, 1, 2, 3].map(n => (
                        <option key={n} value={n}>Position {n + 1}</option>
                      ))}
                    </select>
                    <span className="text-sm dark:text-gray-400">{category.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
