import axios from 'axios';

const SELLIX_CONFIG = {
  productId: '67247dc8e1fe5',
  merchantId: 'Loyalists',
  apiKey: '9hPSKnN3Yr45U6mfRPhxkvNTDIURJhYNd3nQQ137niHAfWCJNxtYML7cNMSwldRp',
  baseUrl: 'https://dev.sellix.io/v1',
  devPassword: 'dantheman'
};

class SellixManager {
  constructor() {
    this.axios = axios.create({
      baseURL: SELLIX_CONFIG.baseUrl,
      headers: {
        'Authorization': `Bearer ${SELLIX_CONFIG.apiKey}`,
        'X-Sellix-Merchant': SELLIX_CONFIG.merchantId
      }
    });
  }

  checkDevAccess(password) {
    return password === SELLIX_CONFIG.devPassword;
  }

  async validateStoredAccess() {
    const devPassword = localStorage.getItem('stalkfun_dev_access');
    const storedAccess = localStorage.getItem('stalkfun_access');

    if (devPassword && this.checkDevAccess(devPassword)) {
      return true;
    }

    if (storedAccess) {
      try {
        const accessData = JSON.parse(storedAccess);
        if (new Date(accessData.expiresAt) > new Date()) {
          return true;
        } else {
          // Clear expired access data
          localStorage.removeItem('stalkfun_access');
        }
      } catch (error) {
        console.error('Error parsing stored access:', error);
        localStorage.removeItem('stalkfun_access');
      }
    }

    return false;
  }

  async getCustomerByEmail(email) {
    const options = {
      method: 'GET',
      url: `${SELLIX_CONFIG.baseUrl}/customers/${email}`,
      headers: {
        'Authorization': `Bearer ${SELLIX_CONFIG.apiKey}`,
        'X-Sellix-Merchant': SELLIX_CONFIG.merchantId
      }
    };

    try {
      const response = await axios(options);
      console.log('API Response:', response.data);
      return response.data;
    } catch (error) {
      console.error('API Error:', error.response?.data || error.message);
      throw error;
    }
  }

  async getSubscriptionStatus(email) {
    const devPassword = localStorage.getItem('stalkfun_dev_access');
    if (devPassword && this.checkDevAccess(devPassword)) {
      return {
        isActive: true,
        expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
      };
    }

    try {
      const response = await this.getCustomerByEmail(email);
      
      if (response.status === 200 && response.data?.customer) {
        const customer = response.data.customer;
        
        // Check for active subscription
        const activeSubscription = customer.subscriptions?.find(sub => 
          sub.status === 'ACTIVE' && 
          sub.product_id === SELLIX_CONFIG.productId
        );

        if (activeSubscription) {
          return {
            isActive: true,
            expiresAt: new Date(activeSubscription.current_period_end * 1000).toISOString()
          };
        }

        // Check for completed orders
        const completedOrder = customer.invoices?.find(inv => 
          inv.status === 'COMPLETED' && 
          inv.product_id === SELLIX_CONFIG.productId
        );

        if (completedOrder) {
          const orderDate = new Date(completedOrder.created_at * 1000);
          const expiryDate = new Date(orderDate);
          expiryDate.setDate(expiryDate.getDate() + 30);

          return {
            isActive: expiryDate > new Date(),
            expiresAt: expiryDate.toISOString()
          };
        }
      }

      return { isActive: false };
    } catch (error) {
      console.error('Subscription check error:', error);
      throw error;
    }
  }
}

export const sellixManager = new SellixManager();