import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { useSwipeable } from 'react-swipeable';

export function Header({ darkMode, setDarkMode }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Swipe handlers
  const handlers = useSwipeable({
    onSwipedUp: () => setMenuOpen(true),
    onSwipedDown: () => setMenuOpen(false),
    trackTouch: true,
    trackMouse: true,
  });

  return (
    <header className="border-b dark:border-gray-800">
      <div className="max-w-7xl mx-auto px-4 py-3">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex items-center gap-6">
            <h1 className="text-xl font-bold">
              <span className="dark:text-white text-gray-900">Stalk</span>
              <span className="text-yellow-400">Fun</span>
            </h1>
          </div>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-gray-500 dark:text-gray-400 focus:outline-none"
            onClick={toggleMenu}
          >
            {menuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Links and Theme Toggle */}
          <div
            {...handlers}
            className={`${
              menuOpen ? 'block' : 'hidden'
            } absolute top-0 left-0 w-full h-screen bg-white dark:bg-[#1c1d25] md:static md:h-auto md:w-auto md:flex md:bg-transparent transition-all duration-300 ease-in-out z-20`}
          >
            <nav className="flex flex-col items-center gap-6 p-8 pt-20 md:flex-row md:p-0 md:pt-0 md:gap-4 md:items-center">
              <button
                onClick={toggleMenu}
                className="absolute top-5 right-5 text-gray-500 dark:text-gray-400 md:hidden"
              >
                <X size={24} />
              </button>
              <a
                href="#how-it-works"
                className="text-gray-500 dark:text-gray-400 transition-all duration-50 hover:font-bold"
              >
                How it works
              </a>
              <a
                href="#support"
                className="text-gray-500 dark:text-gray-400 transition-all duration-50 hover:font-bold"
              >
                Support
              </a>
              <a
                href="#stalkfun"
                className="text-gray-500 dark:text-gray-400 transition-all duration-50 hover:font-bold"
              >
                stalkfun
              </a>
              <a
                href="#twitter"
                className="text-gray-500 dark:text-gray-400 transition-all duration-50 hover:font-bold"
              >
                @stalkfun
              </a>
              <button
                onClick={() => setDarkMode(!darkMode)}
                className="p-2 rounded-full dark:bg-gray-100 bg-[#1c1d25] dark:text-gray-400 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
              >
                {darkMode ? '☀️' : '🌙'}
              </button>
              <button className="px-4 py-2 dark:bg-white bg-[#1c1d25] dark:border-[#2e2f3b] border-gray-200 border dark:text-gray-900 text-white hover:bg-[#252632] font-bold rounded-full dark:hover:bg-gray-50 transition-colors">
                Connect Wallet
              </button>
            </nav>
          </div>
        </div>
      </div>

      {/* Overlay for when menu is open */}
      {menuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30 z-10 md:hidden"
          onClick={toggleMenu}
        ></div>
      )}
    </header>
  );
}
